//import 'bootstrap/dist/js/bootstrap'
import 'assets/portal/scss/index'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

document.addEventListener('turbolinks:load', function() {
  console.log('[portal]');

  document.querySelectorAll('input[name="association_membership[category_id]"]').forEach((el) => {
    el.addEventListener('click', (event) => {
      const element = event.target;
      const receiptRequired = element.dataset.receiptRequired;
      const receiptPanel = document.getElementById('receipts-panel');
      const fileInput = document.getElementById('association_membership_document');
      const submitButton = document.querySelector('form input[type="submit"]');

      if(receiptRequired === 'true') {
        receiptPanel.classList.add("shown");
        fileInput.required = true;
      } else {
        fileInput.value = '';
        fileInput.required = false;
        receiptPanel.classList.remove("shown");
      }

      submitButton.scrollIntoView({behavior: "smooth"});
    })
  })

  document.querySelectorAll('input[name="ticket[ticket_category_id]"]').forEach((el) => {
    el.addEventListener('click', (event) => {
      const element = event.target;
      const receiptRequired = element.dataset.receiptRequired;
      const receiptPanel = document.getElementById('receipts-panel');
      const fileInput = document.getElementById('ticket_document');
      const submitButton = document.querySelector('form input[type="submit"]');

      console.log(fileInput)

      if(receiptRequired === 'true') {
        receiptPanel.classList.add("shown");
        fileInput.required = true;
      } else {
        fileInput.value = '';
        fileInput.required = false;
        receiptPanel.classList.remove("shown");
      }

      submitButton.scrollIntoView({behavior: "smooth"});
    })
  })

  document.querySelectorAll('.accreditation-form .fields .sub-fields').forEach((el) => {
    const button = document.querySelector(".accreditation-form input[type='submit']");

    if(button)
      button.disabled = false;
  });

  const add_button = document.querySelector('.accreditation-form .button-add');
  if(add_button) {
    add_button.addEventListener('click', (event) => {
      const button = document.querySelector(".accreditation-form input[type='submit']");

      if(button)
        button.disabled = false;
    })
  }

  const menu_toggle_button = document.querySelector('.portal-sidebar a.menu-toggle');
  if(menu_toggle_button) {
    menu_toggle_button.addEventListener('click', (event) => {
      const menu = document.querySelector("#portal-sidebar");
      menu.classList.toggle('open');
      event.preventDefault();
    })
  }
});
